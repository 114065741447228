/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Changes the list_create block to use a +/- mutator UI.
 */

import Blockly from 'blockly/core';
import { createPlusField } from './field_plus';
import { createMinusField } from './field_minus';

/* eslint-enable quotes */
// UNPACK CREATE
const unpackMutator = {
    /**
     * Boolean of item inputs the block has.
     * @type {boolean}
     */
    expanded_: false,

    /**
     * Creates XML to represent if block is expanded.
     * @return {!Element} XML storage element.
     * @this {Blockly.Block}
     */
    mutationToDom: function () {
        const container = Blockly.utils.xml.createElement('mutation');
        container.setAttribute('expanded', this.expanded_);
        return container;
    },

    /**
     * Parses XML to restore the text inputs.
     * @param {!Element} xmlElement XML storage element.
     * @this {Blockly.Block}
     */
    domToMutation: function (xmlElement) {
        const unpackState = xmlElement.getAttribute('expanded') === "true" ? true : false;
        this.expanded_ = unpackState;
        if (unpackState) {
            this.appendStatementInput('EXPANDED_STATEMENT');
        }
    },

    /**
     * Callback for the plus image. Adds an input to the end of the block and
     * updates the state of the minus.
     */
    plus: function () {
        this.addPart_();
        let dataObj = JSON.parse(this.data);
        dataObj.fieldData['CHANGED'] = true;
        this.data = JSON.stringify(dataObj);
    },

    minus: function () {
        this.removePart_();
    },

    /**
     * Adds additional unpackable parameters
     * @this {Blockly.Block}
     * @private
     */
    addPart_: function () {
        this.getInput('EMPTY').removeField('PLUS');
        this.getInput('EMPTY').insertFieldAt(0, createMinusField(), 'MINUS');
        this.appendStatementInput('EXPANDED_STATEMENT');
        const unpackData = JSON.parse(this.data).unpackBlocks;
        const fieldData = JSON.parse(this.data).fieldData;
        if (JSON.parse(this.data).contentXml === ''){
            for (let blockGroup of unpackData.reverse()) {
                // instantiate parent
                const parentBlock = this.workspace.newBlock(blockGroup.parent);
                parentBlock.setMovable(false);
                // parentBlock.setEditable(false);
                // set field values in parent block from block data
                for (let field of Object.keys(fieldData)) {
                    if (parentBlock.getField(field)) {
                        parentBlock.setFieldValue(fieldData[field], field)
                    }
                }
                parentBlock.initSvg();
                this.workspace.render();
                // connect parent to unpacked block
                const parentConnection = parentBlock.previousConnection;
                this.getInput('EXPANDED_STATEMENT').connection.connect(parentConnection);
                if (blockGroup.condition) {
                    const conditionBlock = this.workspace.newBlock(blockGroup.condition.block);
                    // conditionBlock.setMovable(false);
                    parentBlock.getInput(blockGroup.condition.input).connection.connect(conditionBlock.outputConnection);
                    if (blockGroup.condition.field) {
                        conditionBlock.setFieldValue(blockGroup.condition.field.value, blockGroup.condition.field.name);
                    }
                    conditionBlock.initSvg();
                    this.workspace.render();
                }
                // connect children to parent block
                if (blockGroup.children) {
                    for (let child of blockGroup.children.reverse()) {
                        const childBlock = this.workspace.newBlock(child);
                        // childBlock.setMovable(false);
                        // set field values in parent block from block data
                        for (let field of Object.keys(fieldData)) {
                            if (childBlock.getField(field)) {
                                childBlock.setFieldValue(fieldData[field], field)
                            }
                        }
                        childBlock.initSvg();
                        this.workspace.render();
                        let childConnection = childBlock.previousConnection;
                        (parentBlock.inputList[1].connection).connect(childConnection);
                    }
                }
            }
        }
        else{
            let dataObj = JSON.parse(this.data);
            let blockDom = Blockly.Xml.domToBlock(Blockly.Xml.textToDom(dataObj.contentXml), this.workspace);
            this.getInput('EXPANDED_STATEMENT').connection.connect(blockDom.previousConnection);
        }
        this.expanded_ = true;
        let dataObj = JSON.parse(this.data);
        let count = 0;
        for (let child of this.getChildren()) {
            if (count === 0) {
                dataObj.contentXml = Blockly.Xml.domToText(Blockly.Xml.blockToDom(child));
            }
            else {
                dataObj.contentXml += Blockly.Xml.domToText(Blockly.Xml.blockToDom(child));
            }
            ++count;
        }
        this.data = JSON.stringify(dataObj);
    },

    /**
     * Hides additional parameters
     * @this {Blockly.Block}
     * @private
     */
    removePart_: function () {
        this.getInput('EMPTY').removeField('MINUS');
        this.getInput('EMPTY').insertFieldAt(0, createPlusField(), 'PLUS');
        this.expanded_ = false;
        for (const child of this.getChildren()) {
            child.dispose();
        }
        this.removeInput('EXPANDED_STATEMENT', true);
    }
};

/**
 * Updates the shape of the block to packed mode.
 * @this {Blockly.Block}
 */
const mutatorHelper = function () {
    this.getInput('EMPTY').insertFieldAt(0, createPlusField(), 'PLUS');
};

Blockly.Extensions.registerMutator('general_mutator',
    unpackMutator, mutatorHelper);
