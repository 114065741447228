<template>
    <div id="chart" class="chart-container">
        <component :is="chartComponent" :workspace="workspace"></component>
    </div>
</template>

<script>
export default {
    name: 'chartComponent',
    props: ['workspace'],
    data(){
        return{
            chartComponent: ''
        }
    },
    mounted()
    {
        const chartPath = this.$store.state.taskInfo.taskId + 'Chart.vue';
        import(`@/components/chartComponents/${chartPath}`).then(module => {
            this.chartComponent = module.default;
        });
    }
}
</script>

<style scoped>
.chart-container {
    font-weight: 500;
    height: 100%
}
</style>