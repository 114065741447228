import Blockly from 'blockly'
import { netlogoGenerator } from '@/blocklyHelpers/netlogoGenerator';
export let baseBlockLibrary = {};
import '@blockly/field-slider';


baseBlockLibrary = {
    "kind": "categoryToolbox",
    "contents": [
        {
            "kind": "category",
            "name": "General",
            "colour": "#00836B",
            "contents": [
                {
                    "kind": "block",
                    "type": "controls_if"
                },
                {
                    "kind": "block",
                    "type": "logic_operation"
                },
                {
                    "kind": "block",
                    "type": "logic_negate"
                },
            ]
        },
    ]
}

Blockly.defineBlocksWithJsonArray([{
    "type": "set",
    "message0": "setup %1",
    "args0": [
        {
            "type": "field_image",
            "src": require("@/assets/start-flag.png"),
            "width": 15,
            "height": 15,
            "alt": "*",
            "flipRtl": false
        }
    ],
    "message1": "%1",
    "args1": [
        {
            "type": "input_statement",
            "name": "SET"
        }
    ],
    "style": "netlogo_blocks"
},
{
    "type": "go",
    "message0": "go",
    "message1": "%1",
    "args1": [
        {
            "type": "input_statement",
            "name": "GO"
        }
    ],
    "style": "netlogo_blocks"
},
{
    "type": "mouse_click",
    "message0": "on mouse click %1",
    "args0": [
        {
            "type": "field_image",
            "src": require("@/assets/click.png"),
            "width": 15,
            "height": 15,
            "alt": "*",
            "flipRtl": false
        }
    ],
    "message1": "%1",
    "args1": [
        {
            "type": "input_statement",
            "name": "MOUSE"
        }
    ],
    "style": "netlogo_blocks"
},
]);

// If else block
netlogoGenerator['controls_ifelse'] = netlogoGenerator['controls_if'];

// Operations 'and', 'or'
netlogoGenerator['logic_operation'] = function (block) {
    let operator = (block.getFieldValue('OP') == 'AND') ? 'and' : 'or';
    let order = (operator == 'and') ? netlogoGenerator.ORDER_LOGICAL_AND :
        netlogoGenerator.ORDER_LOGICAL_OR;
    let argument0 = netlogoGenerator.valueToCode(block, 'A', order);
    let argument1 = netlogoGenerator.valueToCode(block, 'B', order);
    if (!argument0 && !argument1) {
        // If there are no arguments, then the return value is false.
        argument0 = 'false';
        argument1 = 'false';
    } else {
        // Single missing arguments have no effect on the return value.
        let defaultArgument = (operator == 'and') ? 'true' : 'false';
        if (!argument0) {
            argument0 = defaultArgument;
        }
        if (!argument1) {
            argument1 = defaultArgument;
        }
    }
    let code = argument0 + ' ' + operator + ' ' + argument1;
    return [code, order];
};

// Negation operator
netlogoGenerator['logic_negate'] = function (block) {

    let order = netlogoGenerator.ORDER_LOGICAL_NOT;
    let argument0 = netlogoGenerator.valueToCode(block, 'BOOL', order) ||
        'true';
    let code = 'not ' + argument0;
    return [code, order];
};