<template>
    <div class="simulation-component">
        <!-- Top Title -->
        <div class="title">
            <div class="label">Model</div>
            <div class="component-buttons">
                <button class="screenshot"></button>
            <button class="change-mode" :class="$store.state.viewMode === 'main' ? 'expand':'shrink'" v-on:click="changeViewMode"></button>
            </div>
        </div>
        <!-- Netlogo Default Buttons -->
        <div class="controls-container">
            <div class="netlogo-buttons" v-if="ready">
                <div class="simulation-buttons">
                    <button v-on:click="setupClicked" class="widget buttons flag" title="setup"><img src="@/assets/start-flag.png" alt="start flag" /></button>
                    <button v-on:click="goClicked" class="widget buttons" title="go"><img id="go-button" :class= "playing ? 'go-pause' : 'go-play'" v-bind:src= "playing ? require('@/assets/pause.png'): require('@/assets/play.png')" alt="play/pause button" /></button>
                    <button v-on:click="goOnceClicked" class="widget buttons" title="go once" v-bind:disabled= "playing ? true: false"><img src="@/assets/play-once.png" alt="play once" /></button>
                    <div>model <br> speed</div>
                    <div class="speed-container">
                        <input v-on:input="onSliderUpdate" type="range" min="-1" max="1" value="0" step="0.01" class="slider" id="speedRange">
                    </div>
                </div>
            </div>
        </div>
        <!-- NetLogo Simulation Canvas / iframe -->
        <div id="netlogo-content" class="content">
            <div id="netlogo" class="iframe-container">
                <iframe style="width: 100%; height: 100%;" v-bind:src="iframe" :class="{ active:!recompiling || !setupHasBeenClicked || errorMessage, loading: (recompiling && !errorMessage)}"/>
                <div class="loader" :class="{ hide:(ready && (!recompiling || !setupHasBeenClicked) || errorMessage), show: recompiling && !errorMessage}">
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    <p v-if="!ready" class="waiting"></p>
                    <p v-if="!ready" class="loading"></p>
                    <div class="buttons">
                        <button id="sendMessage" class="button" disabled="disabled" v-if="!setupHasBeenClicked"></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- NetLogo Widget Buttons -->
        <div v-if="ready" class="widgets">
            <!-- Dynamic content component for simulation specific components with a function that passes data back to parent -->
            <component :is="widgetComponent" v-on:sendValue="onReceiveVal" :ticks="ticks" ref="widgetComponent"></component>
        </div>
    </div>
</template>

<script>
import Blockly from 'blockly';
import $ from 'jquery';
import { netlogoGenerator } from '@/blocklyHelpers/netlogoGenerator';
export default {
    name: 'SimulationComponent',
    props: ['workspace'],
    data(){
        return{
            // distribution code for this task (just the .nlogo file)
            baseNLogo: '',
            errorMessage: false,
            goFunction: 'blocks-go',
            // iframe: 'http://localhost:9000/launch',
            iframe: new URLSearchParams(window.location.search).get('debug') ? "http://localhost:9000/launch" : "https://galapagos.fablevision-dev.com/launch",
            inkSpread: 0,
            loaded: false,
            newPatchSize: '',
            // this is the entire netlogo script as a string including blocks
            nlscript: '',
            playing: false,
            preNLogo: '',
            ready: false,
            recompiling: false,
            setupFunction: 'blocks-set',
            setupHasBeenClicked: false,
            simulationRunning: false,
            temp: 25,
            ticks: 0,
            widgetComponent: '', 
        }
    },
    mounted(){
        // Import widget component with webpack
        const widgetPath = this.$store.state.taskInfo.taskId + 'Widget.vue';
        import(`@/components/widgetComponents/${widgetPath}`).then(module => {
            this.widgetComponent = module.default;
        });
        // Wait for blockly workspace to setup
        setTimeout(() => {
            this.resizeNetlogo();
            this.initialize();
        }, 500);
    },
    methods:
    {
        setupClicked(){
            $('.flag').removeClass('pulse');
            this.completeClicked = false;
            // Clears out chart
            this.$store.commit('clearData');
            this.playing = false;
            // Do this stuff only the first time a model is loaded
            if (!this.setupHasBeenClicked){
                this.resizeNetlogo();
                // this.setupFunction = this.$store.state.taskInfo.setupFunction;
                $("iframe").get(0).contentWindow.postMessage({type: "fv-button-click", buttons: [{name: this.setupFunction}]}, "*");
                this.setupHasBeenClicked = true;
            }
            // Recompile subsequent times on the setup button
            else{
                this.$store.commit('incrementRunCount');
                new URLSearchParams(window.location.search).get('debug') ? $("iframe").get(0).contentWindow.postMessage({type: "fv-button-click", buttons: [{name: this.setupFunction}]}, "*") : this.recompileClicked();
            }
            // Initiate the chart data
            if(this.$store.state.taskInfo.taskId === 'diffusion'){
                this.inkSpread = 0;
            }
            this.ticks = 0;
            this.$store.commit('appendData', {x: this.ticks, y: parseInt(this.inkSpread)});

            this.$refs.widgetComponent.onTemperatureUpdate();
            // for data logging
            // console.log('setup button clicked')
            if(window.fv){
                console.log('setup clicked')
                window.fv.saveEvent( 'setup clicked', null, Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(this.$props.workspace, true)))
            }
            this.resizeNetlogo();
        },
        goClicked(){
            console.log('go clicked')
            this.simulationRunning = true;
            this.playing = !this.playing;
            // Differentiate between play and pause (when go forever is true)
            if(this.playing) {
                $("iframe").get(0).contentWindow.postMessage({type: "fv-button-click", buttons: [{name: this.goFunction, forever:true}]}, "*");
            } else {
                $("iframe").get(0).contentWindow.postMessage({type: "fv-button-click", buttons: [{name: this.goFunction, forever:false}]}, "*");
            }
            if(this.$store.state.taskInfo.taskId === 'diffusion'){
                this.$refs.widgetComponent.onGridUpdate();
                this.$refs.widgetComponent.onTemperatureUpdate();
            }
            // for data logging
            // console.log('go button clicked')
            if(window.fv){
                console.log('go clicked')
                window.fv.saveEvent( 'go clicked', null, Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(this.$props.workspace, true)))
            }
        },
        goOnceClicked(){
            // Equivalent to Go Once using the function defined in the taskDescriptions.json
            $("iframe").get(0).contentWindow.postMessage({type: "fv-button-click", buttons: [{name: this.goFunction}]}, "*");
        },
        recompileClicked(){
            $("iframe").get(0).contentWindow.postMessage({type: "fv-set-error-message", visible: false}, "*");
            this.recompiling = true;
            // Replace .nlogo file with blockly code and remove the widget definition at the bottom of the file
            // this.preNLogo = this.baseNLogo.split('; --- END BLOCKLY GENERATED NETLOGO ---')[0];
            // this.nlscript = this.preNLogo.replace(';BLOCKLY CODE GOES HERE', this.getCodeFromBlocks());
            // $("iframe").get(0).contentWindow.postMessage({type: "fv-recompile", script: this.nlscript}, "*");
            // console.log(this.nlscript)
            this.nlscript = this.baseNLogo.replace(';BLOCKLY CODE GOES HERE', this.getCodeFromBlocks());
            // console.log(this.nlscript)
            $("iframe").get(0).contentWindow.postMessage({type: "fvloadscript", script: this.nlscript}, "*");
            this.resizeNetlogo();
            // $("#sendMessage").click(this.sendTestMessage);
        },
        getCodeFromBlocks(){
            return netlogoGenerator.workspaceToCode(this.$props.workspace);
        },
        initialize(){
            let fvreadyCount = 0;
            // Listen for messages from iframe
            window.addEventListener("message", (e) => {
                const type = e.data.type;
                if(e.data.type ==="fv-actions-registered"){
                    // console.log(e.data.type)
                }
                switch(type) {
                    case "fvready":
                        if(fvreadyCount === 0){
                            fvreadyCount += 1;
                            $('#sendMessage').click();
                        }
                        $("button").attr("disabled", null);
                        $(".loader").addClass("ready");
                    break;
                    case "fvrunning":
                        this.ready = true;
                        if (!this.loaded) {
                            this.loaded = true;
                        }
                        $(".iframe-container").addClass("render");
                        // Simulate a setup click on initial load
                        if (!this.setupHasBeenClicked && this.ready){
                            this.setupClicked();
                        }
                        if(this.recompiling){
                            this.recompiling = false;
                            this.resizeNetlogo();
                            $("iframe").get(0).contentWindow.postMessage({type: "fv-button-click", buttons: [{name: this.setupFunction}]}, "*");
                        }
                    break;
                    case "fv-recompile-complete":
                        this.recompiling = false;
                        $("iframe").get(0).contentWindow.postMessage({type: "fv-button-click", buttons: [{name: this.setupFunction}]}, "*");
                    break;
                    case "fv-tick-count":
                        this.ticks = e.data.count;
                        // console.log('ticks', this.ticks)
                        // Update chart with current temperature
                        if(this.$store.state.taskInfo.taskId === 'diffusion' && this.ticks > 0){
                            this.$store.commit('appendData', {x: this.ticks, y: parseInt(this.inkSpread)});
                        }
                    break;
                    case "fv-canvas-click":
                    break;
                    case "fv-monitor":
                        if(e.data.displayName === 'ink spread (%)'){
                            this.inkSpread = parseInt(e.data.value);
                        }
                    break;
                    case "fv-get-error-message":
                        this.errorMessage = e.data.visible;
                    break;
                    case "fv-actions-registered":
                        if(this.$store.state.taskInfo.taskId === 'diffusion' && this.$refs.widgetComponent){
                            this.$refs.widgetComponent.onGridUpdate();
                            this.$refs.widgetComponent.onTemperatureUpdate();
                        }
                    break;
                    default:
                }
            });
            // Listen for window resize to adjust the size of the netlogo canvas
            window.addEventListener("resize", this.resizeNetlogo);
            window.addEventListener("complete", () =>{
                if(this.playing){
                    this.goClicked();
                    if(this.$store.state.taskInfo.taskId === 'diffusion' && this.$store.state.secondChart.data.datasets[0].data){
                        // multi run
                        // const length = this.$store.state.chart.data.datasets[this.$store.state.runCount].data.length;
                        // let spread = this.$store.state.chart.data.datasets[this.$store.state.runCount].data[length - 1];
                        // single run
                        const length = this.$store.state.chart.data.datasets[0].data.length;
                        let spread = this.$store.state.chart.data.datasets[0].data[length - 1];

                        this.$store.state.secondChart.data.datasets[0].data.push({x: this.temp, y: spread.y});
                    }
                    this.$store.state.secondChart.update();
                    if(window.fv){
                        window.fv.saveChartData([{
                            id: 'ink spread over time',
                            data: this.$store.state.chart.data.datasets[0].data
                        },{
                            id: 'ink spread by temperature',
                            data: this.$store.state.secondChart.data.datasets[0].data
                        }])
                    }
                }
            })
            this.loadTestScript();
        },
        loadTestScript() {
            // TODO get script from back end for the specific task
            
            const taskScript = this.$store.state.taskInfo.script;
            // console.log(taskScript)
            const script = new URLSearchParams(window.location.search).get('debug') ? this.$store.state.taskInfo.debugFile : taskScript;

            this.getCodeFromBlocks();
            $.ajax({
                url: script,
                success: this.onLoadScript,
                dataType: "text",
                mimeType: "text/plain"
            })
        },
        onLoadScript(e) {
            this.baseNLogo = e;
            // console.log(this.baseNLogo)
            this.nlscript = e.replace(';BLOCKLY CODE GOES HERE', this.getCodeFromBlocks());
            // console.log(this.nlscript)
            $("#sendMessage").click(this.sendTestMessage);
        },
        sendTestMessage() {
            $(".loader").removeClass("ready").addClass("loading");
            $("iframe").get(0).contentWindow.postMessage({type: "fvloadscript", script: this.nlscript}, "*");
        },
        onSliderUpdate() {
            // console.log('updating speed')
            const value = $("#speedRange").val();
            // Used to change progress of slider color dynamically
            const percent = (value - ($("#speedRange")[0].min)) / ($("#speedRange")[0].max-$("#speedRange")[0].min) * 100;
            $("#speedRange").css('background','linear-gradient(to right, #293AD8 0%, #293AD8 ' + percent + '%, #ADADAD ' + percent + '%, #ADADAD 100%)');
            $("iframe").get(0).contentWindow.postMessage({type: "fv-speed", speed: value}, "*");
        },
        resizeNetlogo(){
            let dimension;
            // Calculate new patch size based on worldWidth and 15 pixels smaller than the content window so there is no overlap on the borders
            if ($(".content").height() < $(".content").width()){
                this.newPatchSize = ($(".content").height() - 15) / this.$store.state.taskInfo.worldWidth;
            }
            else{
                this.newPatchSize = ($(".content").width() - 15) / this.$store.state.taskInfo.worldWidth;
            }
            this.newPatchSize = this.newPatchSize.toFixed(2);
            
            // Model needs to redraw patches in go for this to work
            $("iframe").get(0).contentWindow.postMessage({type: "fv-patch-size", patchSize: parseFloat(this.newPatchSize)}, "*");
            // Calculate the netlogo div size based on patch size
            dimension = this.newPatchSize * this.$store.state.taskInfo.worldWidth;
            $("#netlogo").css('height', dimension);
            $("#netlogo").css('width', dimension);
        },
        changeViewMode(){
            // Swap view mode to show larger simulation
            if(this.$store.state.viewMode === 'main'){
                this.$store.commit('changeMode', 'model');
            }
            else{
                this.$store.commit('changeMode', 'main');
            }
            this.resizeNetlogo();
        },
        onReceiveVal(widgetParameters){
            // get data from children components
            if(this.$store.state.taskInfo.taskId === 'diffusion'){
                this.temp = parseInt(widgetParameters.value);
            }
            $("iframe").get(0).contentWindow.postMessage({type: widgetParameters.type, buttons: [{name: widgetParameters.name, value: widgetParameters.value }]}, "*");
        },
    }
}
</script>

<style scoped>
.content{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row: 3;
}

#netlogo{
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.component-buttons{
    display: flex;
    width: 70px;
    justify-content: space-evenly;
}

.widgets{
    background-color: #E9E9E9;
    width: 100%;
    border-style: solid;
    border-color: #B5B5B6;
    border-width: 2px 0 0 0;
}

.slider{
    grid-row: 2;
    grid-column: 1;
    justify-self: center;
    align-self: center;
}

.speed-container{
    background-color: #F4F5F7;
    display: flex;
    height: 22px;
    justify-content: center;
    align-items: center;
    width: 40%;
    border-radius: 50px;
}

input[type='range']{
    border-radius: 50px;
    width: 85%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
}

#speedRange {
    height: 2px;
    background: linear-gradient(to right, #293AD8 0%, #293AD8 50%, #ADADAD 50%, #ADADAD 100%);
}

input[type='range']::-webkit-slider-thumb {
    border: 2px solid #515151;
    height: 17px;
    width: 17px;
    border-radius: 50px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type='range']::-moz-range-thumb {
    border: 2px solid #515151;
    height: 17px;
    width: 17px;
    border-radius: 50px;
    background: #FFFFFF;
    cursor: pointer;
}

input[type='range']::-ms-thumb {
    margin-top: 1px;
    border: 2px solid #515151;
    height: 17px;
    width: 17px;
    border-radius: 50px;
    background: #FFFFFF;
    cursor: pointer;
}

.simulation-component{
    display: grid;
    grid-template-rows: 41px 40px minmax(0, 1fr) 100px;
    grid-template-columns: 1fr;
}

.title{
    display: flex;
    border-style: solid;
    border-width: 0 0 2px 0;
    border-color: #B5B5B6;
    padding: 5px 10px;
    justify-content: space-between;
    font-weight: 500;
}

.label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 2;
}

.screenshot{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-style: solid;
    border-color: #B5B5B6;
    border-width: 1px;
    border-radius: 7px;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    padding: 0px;
    background-image: url('../assets/camera.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.netlogo-buttons{
    background-color: #E0E0E0;
    border-style: solid;
    border-color: #B5B5B6;
    border-width: 0 0 2px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.simulation-buttons{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    font-size: 13px;
    align-items: center;
}

.widget{
    width: 30px;
    height: 30px;
}

.widget.text-buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #293AD8;
    color: white;
    border-width: 0px;
    border-radius: 50%;
    font-size: 10px;
    padding: 2px;
    box-sizing: border-box;
}

.widget.buttons {
    background-color: #FFFFFF;
    border-radius: 50%;
    border-width: 0px;
    padding: 0px;
    display: flex;
}

.widget.buttons.flag {
    background-color: #293AD8;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    box-shadow: 0 0 0 0 rgba(41, 58, 216, 1);
    transform: scale(1);
}
.widget.buttons.flag.pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(41, 58, 216, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(41, 58, 216, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(41, 58, 216, 0);
    }
}

.widget.buttons img{
    width: 100%;
    height: 100%;
}

.diffusion-complete button{
    font-size: 10px;
}

html {
    background: #343434;
    font-family: 'Roboto', sans-serif;
    color: white;
}

h1, .buttons {
    text-align: center;
    font-family: inherit;
    color: inherit;
}

.button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: inherit;
    font-size: 1rem;
    color: black;
    background: #FFFFFF;
    border: 0;
    cursor: pointer;
    display: none;
    padding: 0.25rem 0.625rem;
    border-radius: 1.5rem;
}

button:disabled {
    cursor: default;
    opacity: 0.5;
}

iframe {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

iframe.loading {
    display: none !important;
}

.render iframe {
    display: block;
}

.loader p {
    display: none;
    color: #343434;
    text-align: center;
    margin: 0;
    padding: 0;
}

.loader:not(.ready):not(.loading) p.waiting,
.loader.ready p.ready,
.loader.loading p.loading{
    display: block;
}

.loader.ready button {
    display: inline-block;
}

.lds-ellipsis {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

.loader.show {
    display: block !important;
}

.render .loader, .loader.hide{
    display: none;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #bbcff3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

</style>
