import Blockly from 'blockly';

export const netlogoGenerator = new Blockly.Generator('NETLOGO');
/**
 * Order of operation ENUMs.
 * https://developer.mozilla.org/en/JavaScript/Reference/Operators/Operator_Precedence
 */
netlogoGenerator.ORDER_ATOMIC = 0;           // 0 "" ...
netlogoGenerator.ORDER_NEW = 1.1;            // new
netlogoGenerator.ORDER_MEMBER = 1.2;         // . []
netlogoGenerator.ORDER_FUNCTION_CALL = 2;    // ()
netlogoGenerator.ORDER_INCREMENT = 3;        // ++
netlogoGenerator.ORDER_DECREMENT = 3;        // --
netlogoGenerator.ORDER_BITWISE_NOT = 4.1;    // ~
netlogoGenerator.ORDER_UNARY_PLUS = 4.2;     // +
netlogoGenerator.ORDER_UNARY_NEGATION = 4.3; // -
netlogoGenerator.ORDER_LOGICAL_NOT = 4.4;    // !
netlogoGenerator.ORDER_TYPEOF = 4.5;         // typeof
netlogoGenerator.ORDER_VOID = 4.6;           // void
netlogoGenerator.ORDER_DELETE = 4.7;         // delete
netlogoGenerator.ORDER_AWAIT = 4.8;          // await
netlogoGenerator.ORDER_EXPONENTIATION = 5.0; // **
netlogoGenerator.ORDER_MULTIPLICATION = 5.1; // *
netlogoGenerator.ORDER_DIVISION = 5.2;       // /
netlogoGenerator.ORDER_MODULUS = 5.3;        // %
netlogoGenerator.ORDER_SUBTRACTION = 6.1;    // -
netlogoGenerator.ORDER_ADDITION = 6.2;       // +
netlogoGenerator.ORDER_BITWISE_SHIFT = 7;    // << >> >>>
netlogoGenerator.ORDER_RELATIONAL = 8;       // < <= > >=
netlogoGenerator.ORDER_IN = 8;               // in
netlogoGenerator.ORDER_INSTANCEOF = 8;       // instanceof
netlogoGenerator.ORDER_EQUALITY = 9;         // == != === !==
netlogoGenerator.ORDER_BITWISE_AND = 10;     // &
netlogoGenerator.ORDER_BITWISE_XOR = 11;     // ^
netlogoGenerator.ORDER_BITWISE_OR = 12;      // |
netlogoGenerator.ORDER_LOGICAL_AND = 13;     // &&
netlogoGenerator.ORDER_LOGICAL_OR = 14;      // ||
netlogoGenerator.ORDER_CONDITIONAL = 15;     // ?:
netlogoGenerator.ORDER_ASSIGNMENT = 16;      // = += -= **= *= /= %= <<= >>= ...
netlogoGenerator.ORDER_YIELD = 17;           // yield
netlogoGenerator.ORDER_COMMA = 18;           // ,
netlogoGenerator.ORDER_NONE = 99;            // (...)
netlogoGenerator.INDENT = '  ';
netlogoGenerator.SIZE = {
    "SMALL": 1,
    "MEDIUM": 3,
    "BIG": 5
};
netlogoGenerator.SPEED = {
    "ZERO": 0,
    "LOW": 30,
    "MEDIUM": 50,
    "HIGH": 80,
    "TEMP": 'initial-temperature'
};
netlogoGenerator.MASS = {
    "LIGHT": 1,
    "MEDIUM": 3,
    "HEAVY": 5
};
netlogoGenerator.POSITION = {
    "RANDOM": "(-85 + random-float (170)) -85 + random-float (170)",
    "CENTER": "0 0",
    "VERTICAL": "0 (-85 + random-float (170))",
    "HORIZONTAL": "(-85 + random-float (170)) 0",
    "MOUSE": "drop-with-mouse 500"
}
netlogoGenerator.HEADING = {
    "RANDOM": "random-float 360",
    "UP": "0 - (rand / 2) + random (rand)",
    "RIGHT": "90 - (rand / 2) + random (rand)",
    "DOWN": "180 - (rand / 2) + random (rand)",
    "LEFT": "270 - (rand / 2) + random (rand)"
}
netlogoGenerator.EFFECT = {
    "DIRECT": 1,
    "INVERSE": -1,
    "NOEFFECT": 0
}

/**
 * List of illegal variable names.
 * This is not intended to be a security feature.  Blockly is 100% client-side,
 * so bypassing this list is trivial.  This is intended to prevent users from
 * accidentally clobbering a built-in object or function.
 * @private
 */
netlogoGenerator.addReservedWords(
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Lexical_grammar#Keywords
    'ask,set,go,turtle,patches,break,case,catch,class,const,continue,debugger,default,delete,do,else,export,extends,finally,for,function,if,import,in,instanceof,new,return,super,switch,this,throw,try,typeof,var,void,while,with,yield,' +
    'enum,' +
    'implements,interface,let,package,private,protected,public,static,' +
    'await,' +
    'null,true,false,' +
    // Magic variable.
    'arguments,' +
    // Everything in the current environment (835 items in Chrome, 104 in Node).
    Object.getOwnPropertyNames(Blockly.utils.global).join(','));

/**
 * Initialise the database of variable names.
 * @param {!Blockly.Workspace} workspace Workspace to generate code from.
 */
netlogoGenerator.init = function (workspace) {
    // Create a dictionary of definitions to be printed before the code.
    netlogoGenerator.definitions_ = Object.create(null);
    // Create a dictionary mapping desired function names in definitions_
    // to actual function names (to avoid collisions with user functions).
    netlogoGenerator.functionNames_ = Object.create(null);

    if (!netlogoGenerator.variableDB_) {
        netlogoGenerator.variableDB_ =
            new Blockly.Names(netlogoGenerator.RESERVED_WORDS_);
    } else {
        netlogoGenerator.variableDB_.reset();
    }

    netlogoGenerator.variableDB_.setVariableMap(workspace.getVariableMap());

    let defvars = [];
    // Add developer variables (not created or named by the user).
    let devVarList = Blockly.Variables.allDeveloperVariables(workspace);
    for (let i = 0; i < devVarList.length; i++) {
        defvars.push(netlogoGenerator.variableDB_.getName(devVarList[i],
            Blockly.Names.DEVELOPER_VARIABLE_TYPE));
    }

    // Add user variables, but only ones that are being used.
    let variables = Blockly.Variables.allUsedVarModels(workspace);
    for (let i = 0; i < variables.length; i++) {
        defvars.push(netlogoGenerator.variableDB_.getName(variables[i].getId(),
            Blockly.VARIABLE_CATEGORY_NAME));
    }

    // Declare all of the variables.
    if (defvars.length) {
        netlogoGenerator.definitions_['variables'] =
            'let ' + defvars.join(', ') + ';';
    }
    this.isInitialized = true;
};

// Gets all code not just one set
netlogoGenerator.scrub_ = function (block, code, opt_thisOnly) {
    // console.log(code, block, opt_thisOnly)
    const nextBlock =
        block.nextConnection && block.nextConnection.targetBlock();
    let nextCode = '';
    if (nextBlock) {
        nextCode =
            // opt_thisOnly ? '' : ',\n' + netlogoGenerator.blockToCode(nextBlock);
            opt_thisOnly ? '' : netlogoGenerator.blockToCode(nextBlock);
    }
    return code + nextCode;
};