import Vue from 'vue';
import Vuex from 'vuex';
import Blockly from 'blockly';
import { baseBlockLibrary } from '@/data/blockLibraries/baseBlocks.js';
import * as blockStyling from '@/data/blockConfig/blockStyling.json';
import $ from 'jquery';
import _ from 'underscore';
import task from '@/data/taskContent/taskDescriptions.json';
let categories;

Vue.use(Vuex);
categories = baseBlockLibrary.contents;
Blockly.Themes.Custom = Blockly.Theme.defineTheme('custom', {
    'base': Blockly.Themes.Classic,
    'blockStyles': blockStyling.blockStyles,
    'categoryStyles': blockStyling.categoryStyles,
    'componentStyles': blockStyling.componentStyles,
    'fontStyle': blockStyling.fontStyle,
});
Blockly.ContextMenuRegistry.registry.unregister('blockDelete');
Blockly.ContextMenuRegistry.registry.unregister('blockDisable');

export default new Vuex.Store({
    state: {
        blocklyOptions: {
            renderer: 'thrasos',
            comments: true,
            hasCss: true,
            maxInstances: {
                'set': 1,
                'go': 1,
                'mouse_click': 1
            },
            toolbox: {
                'kind': baseBlockLibrary.kind,
                'contents': categories,
            },
            zoom:
            {
                controls: true,
                wheel: true,
                startScale: 1.0,
                maxScale: 3,
                minScale: 0.5,
                pinch: true
            },
            theme: 'custom',
        },
        chart: null,
        secondChart: null,
        // x,y coordinates to graph in chart component by dataset
        // data: [],
        // name of blockly libraries to add in the blockly component
        libraries: [],
        taskInfo: {},
        viewMode: 'main',
        unpackable: true,
        runCount: 0,
    },
    mutations: {
        getTask(state){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let taskId;
            // check if craft instance is available
            if(window["__CONTENT__"]){
                taskId = window["__CONTENT__"].task.title.toLowerCase();
                if (!taskId) {
                    taskId = 'diffusion';
                }
                state.taskInfo = window["__CONTENT__"].task;
                state.taskInfo.taskId = taskId;
            }
            else{
                taskId = urlParams.get('task');
                if (!taskId) {
                    taskId = 'diffusion';
                }
                state.taskInfo = _.find(task, { taskId: taskId });
            }

            state.libraries = [];
            // instantiate Blockly Toolbox
            console.log(state.taskInfo)
            for (let key of Object.keys(state.taskInfo.blocklyLibrary)){
                let lib = state.taskInfo.blocklyLibrary[key].value;
                state.libraries.push(lib);
            }
        },
        getToolboxMode(state){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let packStatus = urlParams.get('unpackable');
            if (packStatus === "false"){
                state.unpackable = false;
            }
            else{
                state.unpackable = true;
            }
        },
        clearLibs(state){
            state.libraries = [];
        },
        clearData(state){
        //     if (state.data.length > 0){
        //         state.chart.data.datasets[state.runCount].data.splice(0, state.data.length);
        //     }
        //     state.data = [];
            if(state.chart){
                state.chart.data.datasets[0].data = [];
            }
        },
        appendData(state, {x, y}){
            // do not duplicate points with the same x coordinate
            if(state.chart){
                // multiple run data
                // state.chart.data.datasets[state.runCount].data.push({x: x, y: y});
                // let length = state.chart.data.datasets[state.runCount].data.length;
                // state.chart.data.datasets[state.runCount].pointBackgroundColor[length - 1] = '#293AD8';
                // state.chart.data.datasets[state.runCount].pointBorderColor[length - 1] = '#293AD8';
                // state.chart.data.datasets[state.runCount].pointStyle[length - 1] = 'circle';

                // single run data
                state.chart.data.datasets[0].data.push({ x: x, y: y });
                let length = state.chart.data.datasets[0].data.length;
                state.chart.data.datasets[0].pointBackgroundColor[length - 1] = '#293AD8';
                state.chart.data.datasets[0].pointBorderColor[length - 1] = '#293AD8';
                state.chart.data.datasets[0].pointStyle[length - 1] = 'circle';
                
                state.chart.update('none');
            }
        },
        changeMode(state, mode){
            state.viewMode = mode;
            switch (mode){
                case 'main':
                    $('.expandedDiv').removeClass('expandedDiv');
                    $('#blockly').show();
                    $('#chart').show();
                    $('#simulation').show();
                    $('#experiment').show();
                    break;
                case 'workspace':
                    $('#blockly').addClass('expandedDiv');
                    $('#simulation').hide();
                    $('#chart').hide();
                    $('#experiment').hide();
                    break;
                case 'model':
                    $('#simulation').addClass('expandedDiv');
                    $('#blockly').hide();
                    $('#chart').hide();
                    $('#experiment').hide();
                    break;
            }
        },
        incrementRunCount(state){
            state.runCount += 1;
        },
        resetCharts(state){
            state.runCount = 0;
        }
    },
    actions: {
        initTask({ commit }) {
            commit("getTask");
            commit("clearLibs");
            commit("getToolboxMode");
        },
    },
    modules: {
    }
})
