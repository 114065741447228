import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;
// Add unimported components to ignore list to prevent warnings.
Vue.config.ignoredElements = ['field', 'block', 'category', 'xml', 'mutation', 'value', 'sep'];

async function loadFonts() {
    const fonts = 
    [{src:'Poppins-Light', weight:300},
     {src:'Poppins-Bold', weight:'bold'},
     {src:'Poppins-Medium', weight:500},
     {src:'Poppins-SemiBold', weight:600},
     {src:'Poppins-Regular', weight:'normal'}]
    
    for (let font of fonts){
        const fontFace = new FontFace('Poppins', 'url(./fonts/' + font.src + '.woff)', { weight: font.weight, style: 'normal' });
        await fontFace.load();
        document.fonts.add(fontFace);
    }
    document.body.classList.add('fonts-loaded');
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
}
loadFonts();
