<template>
  <div class="simulation-view">
    <NavComponent/>
    <div class="task">
            <div class="info">
                {{ $store.state.taskInfo.taskTitle }}
            </div>
            <div class="contents">
                <BlocklyComponent id="blockly" ref="workspace"/>
                <SimulationComponent id="simulation" class="component" :workspace="workspace"/>
                <ExperimentComponent id="experiment" class="component" :workspace="workspace"/>
                <ChartComponent class="component" :workspace="workspace"/>
            </div>
        </div>
  </div>
</template>

<script>
import BlocklyComponent from '@/components/BlocklyComponent.vue';
import ChartComponent from '@/components/ChartComponent.vue';
import ExperimentComponent from '@/components/ExperimentComponent.vue';
import NavComponent from '@/components/NavComponent.vue';
import SimulationComponent from '@/components/SimulationComponent.vue';

export default {
    name: 'Simulation',
    components: {
        BlocklyComponent,
        ChartComponent,
        ExperimentComponent,
        NavComponent,
        SimulationComponent
    },
    data(){
        return {
            task: '',
            workspace: null,
            // runCount: 0
        }
    },
    mounted(){
        this.workspace = this.$refs['workspace'].workspace;
    },
    methods:{
    }
}
</script>

<style>
.simulation-view {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 45px minmax(0, 1fr);
}

#blockly {
    height: 100%;
    border: #B5B5B6;
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
    grid-row-start: 1;
    grid-row-end: 3;
}

#simulation {
    grid-column: 2;
    grid-row: 1;
}

#experiment {
    grid-column: 3;
    grid-row: 1;
}

#chart{
    grid-row: 2;
    grid-column-start: 2;
    grid-column-end: 4;
}

.change-mode{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-style: solid;
    border-color: #B5B5B6;
    border-width: 1px;
    border-radius: 7px;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    padding: 5px;
    background-repeat: no-repeat;
    background-size: contain;
}
.change-mode.expand{
    background-image: url('../assets/expand.png');
}
.change-mode.shrink{
    background-image: url('../assets/shrink.png');
}

.component{
    height: 100%;
    margin: 0;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border: #B5B5B6;
    border-width: 2px;
    border-style: solid;
}

.task {
    justify-self: center;
    align-self: center;
    display: grid;
    grid-template-columns: 20px minmax(0, 1fr) 20px;
    grid-template-rows: 60px minmax(0, 1fr) 20px;
    height: 100%;
    width: 100%;
    align-items: center;
}

.info{
    grid-column: 2;
    grid-row: 1;
    font-weight: 500;
    font-size: 30px;
}

.contents{
    grid-column: 2;
    grid-row: 2;
    display: grid;
    grid-template-columns: minmax(0,5fr) minmax(0, 4fr) minmax(0,3fr);
    grid-template-rows: minmax(0,4fr) minmax(0,1fr);
    gap: 5px;
    width: 100%;
    height: 100%;
    justify-self: center;
    align-self: center;
}

.expandedDiv{
    grid-row-start: 1 !important; 
    grid-row-end: 4 !important;
    grid-column-start: 1 !important;
    grid-column-end: 4 !important;
}

.ui-buttons{
    background-color: #293AD8;
    border-width: 0px;
    color: white;
    font-weight: 500;
    font-size: 17px;
    border-radius: 15px;
    padding: 10px;
}
</style>
