<template>
    <div class="blockly-component">
        <div class="title">
            <div class="label">Workspace</div>
            <button class="change-mode" :class="$store.state.viewMode === 'main' ? 'expand':'shrink'" v-on:click="changeViewMode"></button>
        </div>
        <div class="blocklyDiv" ref="blocklyDiv"></div>
        <xml ref="blocklyToolbox" style="display:none">
            <slot></slot>
        </xml>
    </div>
</template>

<script>

import Blockly from 'blockly';
import './prompt';
import $ from 'jquery';
import { netlogoGenerator } from '@/blocklyHelpers/netlogoGenerator';
import '@/blocklyHelpers/mutations';
export default {
  name: 'BlocklyComponent',
  props: ['options', 'task'],
  data(){
    return {
      devMode: window.location.href.match(/\?debug=true/i) ? true : false,
      defaultBlocks: [],
      pulse: null,
      toolbox: null,
      workspace: null,
      progress: null,
    }
  },
  mounted(){
    // console.log('here')
    // Blockly Options exist in Vuex Store as global variables to avoid duplication errors thrown by Blockly everytime the Component is mounted
    this.workspace = Blockly.inject(this.$refs['blocklyDiv'], this.$store.state.blocklyOptions);


    // Store workspace toolbox to add to later
    this.toolbox = this.workspace.getToolbox();

    let unsubscribe = null
    unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'getTask') {
        for (let lib of this.$store.state.libraries){
            if(lib !== 'general'){
                import(`@/data/blockLibraries/${lib}`).then( module => {
                    for (let category of module[lib].categories){
                        this.toolbox.toolboxDef_.contents.push(category);
                    }
                    this.toolbox.render(this.toolbox.toolboxDef_);
                    this.setupWorkspace();
                });
            }
        }
        unsubscribe();
      }
    })
    // load task, clear libs, and get the Toolbox Mode
    this.$store.dispatch("initTask");
    
    const ws = this.workspace;

    // save workspace to account
    $('#save-workspace').on('click', () => {
        if(window.fv){
            let xml = Blockly.Xml.workspaceToDom(ws);
            let xml_text = Blockly.Xml.domToText(xml);
            window.fv.saveProgress(xml_text);
        }
    })
  },
  methods:{
    // sets up Blockly Workspace
    setupWorkspace(){
        // console.log(window.fv)
        // TODO: Only do this if there is no saved version
    //    console.log(this.$store.state.taskInfo.taskId)
        if(this.$store.state.taskInfo.taskId === 'diffusion'){
            this.defaultBlocks = ['set', 'go', 'mouse_click'];
        }
        else{
            this.defaultBlocks = ['set', 'go'];
        }

        // const defaultBlocks = ['set', 'go', 'mouse_click'];

        // this.addBlockToWorkspaceByDefault(defaultBlocks);
        netlogoGenerator.init(this.workspace);

        this.workspace.addChangeListener(this.checkForOrphanBlocks);

        this.workspace.scrollbar.vScroll.svgGroup_.style.display = 'none';
        this.workspace.scrollbar.hScroll.svgGroup_.style.display = 'none';
        console.log(this.progress)
        if(window.fv){
            window.fv.loadProgress(this.getProgress);
        }
        else{
            this.addBlockToWorkspaceByDefault(this.defaultBlocks);
        }
        switch(this.$store.state.taskInfo.taskId){
            case "diffusion":
                if(this.$store.state.unpackable){
                    this.toolbox.getToolboxItemById("actions").hide();
                    this.toolbox.getToolboxItemById("properties").hide();
                }
                else{
                    this.toolbox.getToolboxItemById("actionsUnpackable").hide();
                    this.toolbox.getToolboxItemById("propertiesUnpackable").hide();
                }
                break;
            // case "wildfires":
            //     if(this.$store.state.unpackable){
            //         this.toolbox.getToolboxItemById("treeActions").hide();
            //         this.toolbox.getToolboxItemById("smokeActions").hide();
            //     }
            //     else{
            //         this.toolbox.getToolboxItemById("treeActionsUnpackable").hide();
            //         this.toolbox.getToolboxItemById("smokeActionsUnpackable").hide();
            //     }
            //     break;
        }
    },
    getProgress(e){
        this.progress = JSON.parse(e).progress;
        if (this.progress){
            const xmlText = Blockly.Xml.textToDom(this.progress);
            Blockly.Xml.domToWorkspace(xmlText, this.workspace);
            this.workspace.scrollCenter();
        }
        else{
            this.addBlockToWorkspaceByDefault(this.defaultBlocks);
        }
    },
    // initiates blocks that should be in the workspace on page load and prevents them from being deleted
    addBlockToWorkspaceByDefault(types){
        if(!this.devMode){
            for (let type in types){
                let block = this.workspace.newBlock(types[type]);
                block.setDeletable(false);
                block.moveBy(0, 200 * type);
                block.initSvg();
                this.workspace.render();
            }
            this.workspace.scrollCenter();
        }
        else if (this.devMode && this.$store.state.taskInfo.taskId === "diffusion"){
            let string = 
            '<xml>'+
                '<block type="set">'+
                    '<statement name = "SET">'+
                        '<block type = "create_particles">'+
                            '<field name = "number">100</field>'+
                            '<field name = "dropdown">water</field>'+
                        '</block>'+
                    '</statement>'+
                '</block>'+
            '</xml>';
            let xml = Blockly.Xml.textToDom(string);
            Blockly.Xml.appendDomToWorkspace(xml, this.workspace);
            string = 
            '<xml>'+
                '<block type="go">'+
                    '<statement name = "GO">'+
                        '<block type = "for_each_particle">'+
                        '<field name = "dropdown">each water particle</field>'+
                            '<statement name = "FOR_EACH">'+
                                '<block type = "move"></block>'+
                            '</statement>'+
                        '</block>'+
                    '</statement>'+
                '</block>'+
            '</xml>';
            xml = Blockly.Xml.textToDom(string);
            Blockly.Xml.appendDomToWorkspace(xml, this.workspace);
            string = 
            '<xml>'+
                '<block type="mouse_click">'+
                '</block>'+
            '</xml>';
            xml = Blockly.Xml.textToDom(string);
            Blockly.Xml.appendDomToWorkspace(xml, this.workspace);
        }
    },
    // grays out blocks that are not connected to a top level block
    checkForOrphanBlocks(e){
        var convert = require('xml-js');
        // for data logging
        // fv.saveEvent("create","particles", "<xml xmlns=\"https://developers.google.com/blockly/xml\"> <block type=\"create_particles\" x=\"-20\" y=\"-93\"> <mutation expanded=\"false\"></mutation> <field name=\"TYPE\">WATER</field> <field name=\"PARTICLE_NUM\">100</field> <data>{\"unpackBlocks\":[{\"parent\":\"for_each_particle\",\"children\":[\"set_speed\",\"set_mass\",\"set_color\",\"set_size\",\"set_position\",\"set_heading\"]}],\"fieldData\":{\"TYPE\":\"WATER\",\"SPEED\":\"TEMP\",\"MASS\":\"MEDIUM\",\"COLOR\":\"CYAN\",\"SIZE\":\"MEDIUM\",\"POSITION\":\"RANDOM\",\"HEADING\":\"RANDOM\",\"CHANGED\":false},\"contentXml\":\"\"}</data> </block> <block type=\"set\" deletable=\"false\" x=\"0\" y=\"0\"></block> <block type=\"go\" deletable=\"false\" x=\"0\" y=\"200\"></block> <block type=\"mouse_click\" deletable=\"false\" x=\"0\" y=\"400\"></block> </xml>");
        switch(e.type){
            case 'create':{
                if(window.fv){
                    console.log('logging create')
                    window.fv.saveEvent( (e.type + ' block'), this.workspace.getBlockById(e.blockId).type, Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(this.workspace, true)))
                }
            }
            break;
            case 'delete':{
                let blockJSON = JSON.parse(convert.xml2json(Blockly.Xml.domToText(e.oldXml), {compact: false, spaces: 4}));
                if(window.fv){
                    console.log('logging delete')
                    window.fv.saveEvent( (e.type + ' block'), blockJSON.elements[0].attributes.type, Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(this.workspace, true)))
                }
            }
            break;
        }
        

        // let dom = Blockly.Xml.workspaceToDom(this.workspace, true);
        // let data = Blockly.Xml.domToPrettyText(dom);
        // console.log(data);
        if(e.type === Blockly.Events.BLOCK_MOVE){
            let createBlocks = this.workspace.getBlocksByType('create_particles', true);
            // console.log(JSON.parse(createBlocks[0].data))
            for (let block of createBlocks){
                const top = block.getRootBlock();
                if(top.type === 'mouse_click'){
                    if(block.getFieldValue('PARTICLE_NUM') > 100){
                        block.setFieldValue(100,'PARTICLE_NUM');
                    }
                    block.getField('PARTICLE_NUM').max_ = 100;
                } 
                else{
                    block.getField('PARTICLE_NUM').max_ = 500;
                } 
            }
        }
        Blockly.Events.disableOrphans(e);
        if(e.type === Blockly.Events.BLOCK_MOVE || e.type === Blockly.Events.BLOCK_CREATE){
            if(this.pulse !== null){
                clearTimeout(this.pulse);
            }
            $('.flag').removeClass('pulse');
            $('.flag').addClass('pulse');
            this.pulse = setTimeout(()=>{
                $('.flag').removeClass('pulse');
            }, 4000);
        }
        if(e.type === Blockly.Events.BLOCK_CHANGE && e?.element === 'field'){
            let block = (this.workspace.getBlockById(e.blockId));
            if(e.name === 'AGENT' && e.newValue === 'SMOKES'){
                block.setStyle('for_smoke_behavior_blocks');
            }
            else if(e.name === 'AGENT' && e.newValue === 'TREES'){
                block.setStyle('forest_control_blocks');
            }
        }
    },
    changeViewMode(){
        if(this.$store.state.viewMode === 'main'){
            this.$store.commit('changeMode', 'workspace');
        }
        else{
            this.$store.commit('changeMode', 'main');
        }
        Blockly.svgResize(this.workspace);
        this.workspace.resize();
    }
  }
}
</script>

<style>
.blocklyDiv {
  height: 100%;
  width: 100%;
  text-align: left;
}
.blocklyPath{
    stroke-width: 3px !important;
}
.blockly-component{
    display: grid;
    grid-template-rows: 41px minmax(0, 1fr);
    grid-template-columns: 1fr;
}
.title{
    display: flex;
    border-style: solid;
    border-width: 0 0 2px 0;
    border-color: #B5B5B6;
    padding: 5px 10px;
    justify-content: space-between;
    font-weight: 500;
    background-color: white;
}

</style>
