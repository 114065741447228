<template>
  <div class="experiment-component">
        <!-- Top Title -->
        <div class="title">
            <div class="label">Experiment</div>
            <button class="screenshot"></button>
        </div>
        <!-- View Code from Blocks for debugging -->
        <!-- <div class="code">
            <button v-on:click="getCodeFromBlocks()" class="ui-buttons">Show NetLogo Code</button>
            <pre v-html="code"></pre>
        </div> -->
        <!-- <div v-if="this.$store.state.taskInfo.taskId === 'diffusion'" class="video-container">
            <video controls muted class="video">
                <source src="@/assets/videos/diffusion-test.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div> -->
          <div class="code">
            <button v-on:click="getCodeFromBlocks()" class="ui-buttons">Show NetLogo Code</button>
            <pre v-html="code"></pre>
        </div>

  </div>
</template>

<script>
import Blockly from 'blockly';
import { netlogoGenerator } from '@/blocklyHelpers/netlogoGenerator';
export default {
    name: 'ExperimentComponent',
    props: ['workspace'],
    data(){
        return{
            code: '',
            baseCode: '',
        }
    },
    methods:
    {
        getCodeFromBlocks(){
            this.code = netlogoGenerator.workspaceToCode(this.$props.workspace) || netlogoGenerator.workspaceToCode(Blockly.getMainWorkspace());
        }
    }
}
</script>

<style scoped>
.experiment-component{
    display: grid;
    grid-template-rows: 41px minmax(0, 1fr);
    grid-template-columns: 1fr;
}

.title{
    display: flex;
    border-style: solid;
    border-width: 0 0 2px 0;
    border-color: #B5B5B6;
    padding: 5px 10px;
    justify-content: space-between;
    background-color: #E0E0E0;
}

.label{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.screenshot{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-style: solid;
    border-color: gray;
    border-width: 1px;
    border-radius: 7px;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    padding: 0px;
    background-image: url('../assets/camera.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.code{
    overflow: scroll;
    padding: 10px;
}

pre{
    max-height: 88%;
}

.video-container{
    position: relative;
    /* width: 100%;
    height: 100%; */
}
.video{
    position: absolute; 
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-width: 100%; 
    max-height: 100%;
    width: auto; 
    height: auto; 
    background-size: cover;
    overflow: hidden;
}
</style>