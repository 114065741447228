<template>
    <div class="index-view">
        <NavComponent/>
        <div class="type-contents">
            <div class="type-title">
                {{ type }}
            </div>
            <div class="type-items">
                <div v-for="item in content" :key="item.taskId" class="type-item">
                <img :src="require('@/assets/thumbnails/' + item.taskId + '.png')"/>
                {{ item.taskId }}
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import NavComponent from '@/components/NavComponent.vue';
import Content from '@/data/taskContent/taskDescriptions.json';
export default {
    name:'Index',
    components:{
        NavComponent
    },
    data(){
        return{
            content: Content,
            type: 'Units'
        }
    }
}
</script>

<style scoped>
.index-view{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 45px minmax(0, 1fr);
}
.type-contents{
    grid-column: 1;
    grid-row: 2;
    display: grid;
    grid-template-columns: 20px minmax(0, 1fr) 20px;
    grid-template-rows: 50px minmax(0, 1fr) 20px;
}
.type-title{
    grid-column: 2;
    grid-row: 1;
    align-self: center;
    font-weight: 500;
    font-size: 30px;
}
.type-items{
    grid-column: 2;
    grid-row: 2;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    overflow: scroll;
    font-weight: 500;
}
.type-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
}
.type-item img{
    width: 90%;
    height: auto;
}


</style>
